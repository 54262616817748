function parseJSONContent(content: any): any | null {
    try {
        return JSON.parse(content);
    } catch (error) {
        return null;
    }
}

export function parseContent(content: any): string {
    const parsedContent = parseJSONContent(content);
    if (parsedContent && typeof parsedContent === 'object' && parsedContent.message) {
        const messageContent = parseJSONContent(parsedContent.message);
        if (messageContent?.genericAttachments) {
            return messageContent?.genericAttachments[0]?.title;
        }else if (parsedContent.message && content.search(/GptResponse/i) !== -1) {
            return parsedContent?.message;
        }
    }
    return content;
}