import React, { useState } from "react";
import {
    Button,
    Text,
    Divider,
    Content,
    Dialog,
    Header,
    Heading,
    TextArea,
    Footer,
} from "@adobe/react-spectrum"
import styles from '../Theme/Styles/styles.css'
import localization from "../lang/localization"

const ActionMenuPopup = (props: any) => {
    const { heading, header, cancelText, primaryText, popupText,
        cancelEvent, primaryEvent, textAreaValue, type, lang } = props;
    const [text, setText] = useState(textAreaValue || '');

    const primaryAction = () => {
        let data;
        let selection: any;
        switch (type) {
            case 'close':
                data = {
                    value: "some data for close conversations"
                };
                break;
            case 'copy':
                selection = window.getSelection();
                document.execCommand('copy', selection.toString());
                data = {
                    value: "some data for copy to clipboard"
                };
                break;
            case 'private':
                data = {
                    value: text
                };
                break;
        }
        primaryEvent(type, data);
    }

    return (
        <Dialog width={480} minHeight={229} maxHeight={511} UNSAFE_className={styles['action-menu-popup']}>
            <Heading>{localization[lang].ACTION_MODAL[heading]}</Heading>
            <Header>{header}</Header>
            <Divider />
            <Content>
                {popupText !== "" ?
                    <Text>{localization[lang].ACTION_MODAL[popupText]}</Text>
                    :
                    <TextArea width={400} height={300} value={text} aria-label="message" onChange={setText} />
                }
            </Content>
            <Footer justifySelf="end">
                <Button variant="secondary" isQuiet onPress={() => cancelEvent(false)}>
                    {localization[lang].ACTION_MODAL[cancelText]}
                </Button>
                <Button variant="cta" onPress={primaryAction}>
                    {localization[lang].ACTION_MODAL[primaryText]}
                </Button>
            </Footer>
        </Dialog>
    )
}

export default ActionMenuPopup;