import React, { useState } from 'react'
import { fetchConversationLegSentiments, generateOrFetchContactLegSentiments } from '../services/api'
import { retryFunction } from '../services/utils';

/**
 * Hook for fetching the sentiments of each contact leg
 */
const useFetchAgentPathSentimentsHook = (url: string) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>();

  const fetchSentiments = async (contactIds: string[]) => {
    // fetch the sentiments from the backend
    setLoading(true)
    setError(null)
    return fetchConversationLegSentiments(url, contactIds)
      .catch((err) => {
        console.log('Error fetching the contact leg sentiments: ', err?.message ?? 'Internal Error.')
        setError(err.message ?? 'Error fetching sentiments')
        throw err
      })
      .finally(() => setLoading(false))
  }

  const fetchOrGenerateSentiments = async (contactIds: string[], initialContactId: string) => {
    // fetch the sentiments or generate from the backend
    setLoading(true)
    setError(null)
    const retryCondition = (
      res: {
        [contactId: string]: string;
    }) => (
      res == null ||
      Object.keys(res ?? {})?.length === 0 ||
      Object.entries(res ?? {}).filter(([key, _val]) => key.slice(-4) === '-BOT')?.some(([_key, val]) => val === "-")
    )
    return retryFunction(
      () => generateOrFetchContactLegSentiments(url, contactIds, initialContactId),
      retryCondition
    )
      .catch((err) => {
        console.log('Error fetching or generating the contact leg sentiments: ', err?.message ?? 'Internal Error.')
        setError(err.message ?? 'Error fetching or generating sentiments')
        throw err
      })
      .finally(() => setLoading(false))
  }

  return {
    error,
    loading,
    fetchSentiments,
    fetchOrGenerateSentiments
  }
}

export default useFetchAgentPathSentimentsHook
