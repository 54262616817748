import axios from 'axios';

type ConversationLegsSentimentResponse = {
  sentiment: {
    [contactId: string]: string;
  };
};

type ContactLegSentimentsResponse = {
  conversationSentimentData: {
    initialContactId: string;
    listOfAllContactIdsSentiment: {
      [contactId: string]: {
        sentiment: string
      }
    }[]
    listOfAllContactIds: string[]
    overallConversationSentiment: string
    timestamp: number
  }
}

function getRTCSAuthorization () {
  console.log('CIP process.env.REACT_APP_NODE_ENV', process.env.REACT_APP_NODE_ENV)
  if(process.env.REACT_APP_NODE_ENV === 'stage2' || process.env.REACT_APP_NODE_ENV ===  'alpha2') {
    return localStorage.getItem('prodCustomPanelToken').replaceAll('"', '') 
  } else return localStorage.getItem('customPanelAccessToken').replaceAll('"', '') 
}


export async function fetchConversationLegSentiments(url: string, contactIds: string[]) {
  try {
    const URL = `${url}/sentiment/contact/closed?contactIds=${contactIds.join(',')}`;
    const headers = { 'x-api-key': 'oac-custom-panel', Authorization: getRTCSAuthorization () ?? '' };
    const response = await axios.get<ConversationLegsSentimentResponse>(URL, { headers });
    return response?.data?.sentiment;
  } catch (err) {
    console.log('Error fetching Conversation Sentiment: ', err?.message ?? 'Internal Error');
    throw err;
  }
}

export async function generateOrFetchContactLegSentiments(url: string, contactIds: string[], initialContactId: string) {
  try {
    const URL = `${url}/sentiment/contact/closed?initialContactId=${initialContactId}`
    const headers =  { 'x-api-key': 'oac-custom-panel', Authorization: getRTCSAuthorization () ?? '' }
    const response = await axios.post<ConversationLegsSentimentResponse>(URL, { contactIds }, { headers })
    return response?.data?.sentiment
  } catch (err) {
    console.log('Error fetching or generating the Contact Leg Sentiments: ', err?.message ?? 'Internal Error')
    throw err
  }
}

export async function fetchClosedContactLegSentiments(url: string, initialContactId: string) {
  try {
    const URL = `${url}/sentiment/conversation?initialContactId=${initialContactId}`
    const headers = { 'x-api-key': 'oac-custom-panel', Authorization: getRTCSAuthorization () ?? '' }
    const response = await axios.get<ContactLegSentimentsResponse>(URL, { headers })
    return response?.data?.conversationSentimentData
  } catch (err) {
    console.log('Error fetching contact leg sentiments for a closed contact: ', err?.message ?? 'Internal Error')
    throw err
  }
}
