import actionTypes from '../actionTypes'

const {
  SET_ACTIVE_CONVERSATION_DATA,
  SET_CONTEXT_DATA,
  CLEAR_OC_STATE,
  SET_CONVERSATION_ARRAY,
  ALLOW_TO_LINK_CASE_FOR_RETURN_CUSTOMER
} = actionTypes

export const setActiveConversationData = (activeConversationData: any) => {
  return {
    type: SET_ACTIVE_CONVERSATION_DATA,
    value: activeConversationData
  }
}

export const setContextData = (contextData: any) => {
  return {
    type: SET_CONTEXT_DATA,
    value: contextData
  }
}

export const clearOCState = (payload: any) => {
  return {
    type: CLEAR_OC_STATE,
    value: payload
  }
}

export const setConversationArray = (payload: any) => {
  return {
    type: SET_CONVERSATION_ARRAY,
    value: payload
  }
}

export const allowToLinkCaseForReturnCustomer = (flag: boolean) => {
  return {
    type: ALLOW_TO_LINK_CASE_FOR_RETURN_CUSTOMER,
    value: flag
  }
}