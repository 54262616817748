import React, { useEffect, useRef } from 'react';
import { Content, Dialog, DialogTrigger, Divider, ActionButton, Text, Flex, Heading, StatusLight, Grid, Button, ProgressCircle } from '@adobe/react-spectrum';

import localization from '../lang/localization'
import { addDuration, formatDuration } from '../utils/timeUtils';
import { agentStatusLight, agentStatusCategory } from '../utils/constants';
import { fetchAgentAuxData } from '../api/auxService';

const StatusItem = ({ status }: { status: string }) => (
  <Flex direction="row" alignItems="baseline">
    <StatusLight variant={agentStatusLight[status]} aria-label={`${status}_status`} role="status" />
    <Text>{status}</Text>
  </Flex>
);

const AUXReport = ({ agentId, agentConsoleServiceApiEndPoint, lang }: { agentId: string, agentConsoleServiceApiEndPoint: string, lang: string }) => {
  
  const [auxData, setAuxData] = React.useState<any[]>([]);
  const [totalDuration, setTotalDuration] = React.useState({ available: '', away: '', startOfDay: '' });
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [latestStatusStartTimeISO, setLatestStatusStartTimeISO] = React.useState('');
  const [currentAUXCategoryStatusDuration, setCurrentAUXCategoryStatusDuration] = React.useState('');
  const auxReportRef = useRef<any>(null);
  
  const agentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const { available, away, startOfDay } = totalDuration;

  useEffect(() => {
    const getAgentAuxData = async () => {
      if (!dialogOpen) return;

      setLoading(true);

      try {
        const response: any = await fetchAgentAuxData({ agentId, agentConsoleServiceApiEndPoint, agentTimezone });
        const auxDataResponse = response?.data?.auxData || [];
        
        setAuxData(auxDataResponse);
        setTotalDuration(response?.data?.totalDuration || {});

        if(!auxDataResponse.length) return;
        
        // To fetch the latest AUX status value
        const lastAgentStatus = auxDataResponse[auxDataResponse.length - 1]?.status;

        // To fetch the latest AUX status start time
        const latestStatusStartTimeISO = auxDataResponse[auxDataResponse.length - 1]?.startTime;
        setLatestStatusStartTimeISO(latestStatusStartTimeISO);
        
        /**
         * Based on the latest AUX status value, get the AUX category's total duration
         *
         * If the last AUX status is 'Case', we need to retrieve the total duration 
         * of the 'Away' category. This allows us to track the amount of time 
         * spent in this specific status category based on the most recent AUX status value.
         */
        // 
        const lastStatusDuration = response?.data?.totalDuration[agentStatusCategory[lastAgentStatus]]
        setCurrentAUXCategoryStatusDuration(lastStatusDuration)
      } catch (error) {
        console.error('Error fetching agent aux status data:', error);
        setAuxData([]);
        setTotalDuration({ available: '', away: '', startOfDay: '' });
      } finally {
        setLoading(false);
      }
    };
    getAgentAuxData();
  }, [dialogOpen]);

  useEffect(() => {
    // Convert the latest AUX status start time to a Date object (UTC)
    const startTime:any = new Date(latestStatusStartTimeISO);
    let timerId: any;

    function startTimer() {
      const intervalId = setInterval(() => {
        const now: any = new Date();
        // Calculate the duration for the latest AUX status based on the difference current time and start time
        const totalSeconds:any = Math.floor((now - startTime) / 1000);
        // Get the duration in Xh Ym Zs format
        const lastStatusDuration = formatDuration(totalSeconds);
        
        // Update the latest AUX status duration with the calculated duration to the Time column
        setAuxData(prevItems => {
          const newItems: any = [...prevItems];
          if (newItems.length > 0) {
            newItems[newItems.length - 1] = {
              ...newItems[newItems.length - 1],
              duration: lastStatusDuration,
            };
          }
          return newItems;
        });
        
        // Update the latest AUX status duration & total time of AUX cateogry in the AUX cateogry duration
        setTotalDuration(prevData => {
            const updatedData: any = { ...prevData };
            if(auxData?.length > 0) {
              const categoryProperty = agentStatusCategory[auxData[auxData.length - 1]?.status];
              updatedData[categoryProperty] = addDuration(currentAUXCategoryStatusDuration, lastStatusDuration);
            }
            return updatedData;
        });
      }, 1000);
      
      return intervalId;
    }

    if(dialogOpen && auxData.length) {
      timerId = startTimer();
    }

    // Cleanup
    return () => {
      clearInterval(timerId);
    };

  }, [dialogOpen, currentAUXCategoryStatusDuration, latestStatusStartTimeISO, auxData.length])


  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleClickOutsideDialog = (event: any) => {
    if (auxReportRef.current && !auxReportRef.current?.UNSAFE_getDOMNode().contains(event.target)) {
      setDialogOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideDialog);
    return () => {
      document.removeEventListener('click', handleClickOutsideDialog);
    };
  }, []);

  return (
    <DialogTrigger isOpen={dialogOpen} type="modal">
      <ActionButton onPress={() => setDialogOpen(true)} marginBottom={7} isQuiet UNSAFE_style={{ color: '#0D66D0' }}>
        {localization[lang].AUX_STATUS_REPORT.STATUS_REPORT}
      </ActionButton>
      <Dialog ref={auxReportRef} width={500}>
        <Heading level={1}>{localization[lang].AUX_STATUS_REPORT.STATUS_REPORT}</Heading>
        <Divider />
        <Content minHeight={300}>
          {
            loading ? (
                <ProgressCircle aria-label="Loading…" UNSAFE_style={{position: 'absolute', left: '50%', top: '50%'}} isIndeterminate />
            ) : (
          <React.Fragment>
            <Flex direction="row" justifyContent="space-around" marginStart={10}>
              {[available, away, startOfDay].map((time, index) => (
                <Heading key={index} level={1} margin={0} flex={1} UNSAFE_style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
                  {time || '-'}
                </Heading>
              ))}
            </Flex>
            <Flex direction="row" justifyContent="space-around">
              {['Available', 'Away', 'Start of Day'].map((status) => (
                <StatusItem key={status} status={status} />
              ))}
            </Flex>
            <div style={{ marginTop: '25px' }}>
              <Grid columns={['5fr', '3fr', '2fr']} marginBottom={20}>
                {['Status', 'Start', 'Time'].map((header, index) => {
                  const localizedHeader = localization[lang].AUX_STATUS_REPORT[header];
                  return (
                    <Heading key={index} level={4} marginStart={10}>
                      {localizedHeader}
                    </Heading>
                  );
                })}

              </Grid>
              <div style={{ maxHeight: '200px', overflow: 'auto', paddingBottom: '0' }}>
                {auxData.map((item: any, index: number) => (
                  <Grid key={index} columns={['5fr', '3fr', '2fr']} gap="size-200" marginBottom={10}>
                    <StatusItem status={item.status} />
                    <Text>{item.formattedStartTime}</Text>
                    <Text>{item.duration || '< 1s'}</Text>
                  </Grid>
                ))}
              </div>
            </div>
          </React.Fragment>)}
        </Content>
        <Button  variant="secondary" onPress={handleClose} width={90} marginBottom={20}  top={20} left={340} gridArea={"footer-start / footer-start / buttonGroup-end / buttonGroup-end"}>
          {localization[lang].AUX_STATUS_REPORT.CLOSE}
        </Button>
      </Dialog>
    </DialogTrigger>
  );
};

export default AUXReport;
