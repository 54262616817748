import React, { useRef, useState } from 'react'
import { DialogTrigger, Divider, ToggleButton, AlertDialog } from '@adobe/react-spectrum'

import styles from '../../Theme/Styles/styles.css'
import { LogIcon } from '../../Theme/Icons/navbar-icons'

// Auto-kill log collector after 1 minute
const CAPTURE_LOG_TIMEOUT = 60000;
const DEFAULT_CONSOLE_LOG_FUNCTION = console.log
const DEFAULT_CONSOLE_ERROR_FUNCTION = console.error

const BrowserLogCapture = (props: any) => {
    const { agentFeatureTags: { oaclogger = 'false'} = {} } = props
    const [toggleLogger, setToggleLogger] = useState(false);
    
    //Creating a reference object for toggleLogger State since state value is lost inside setTimeout
    const toggleLoggerRef = useRef(toggleLogger);
    toggleLoggerRef.current = toggleLogger;

    const alertUser = (e: any) => {
        console.log('Alert User triggered', toggleLogger)
        if(toggleLogger) {
            toggleLogCaptureOff();
            e.preventDefault();
            e.returnValue = '';
        }
    }

    React.useEffect(() => {
        window.addEventListener("beforeunload", alertUser);
        return () => {
            window.removeEventListener("beforeunload", alertUser);
        };
    }, [toggleLogger]);

    const downloadLogFiles = () => {
        // @ts-ignore
        const logData = JSON.stringify(console.logs);
        const logBlob = new Blob([logData], { type: "application/json" });
        const logUrl = URL.createObjectURL(logBlob);
        const logLink = document.createElement("a");

        logLink.download = "oac-logs.log";
        logLink.href = logUrl;
        logLink.click();

        // @ts-ignore
        const errorData = JSON.stringify(console.errors);
        const errorBlob = new Blob([errorData], { type: "application/json" });
        const errorUrl = URL.createObjectURL(errorBlob);
        const errorLink = document.createElement("a");

        errorLink.download = "oac-errors.log";
        errorLink.href = errorUrl;
        errorLink.click();
        // @ts-ignore
        console.logs.length = 0;
        console.log = DEFAULT_CONSOLE_LOG_FUNCTION;

        // @ts-ignore 
        console.errors.length = 0;
        console.error = DEFAULT_CONSOLE_ERROR_FUNCTION; 

        const resourceList = window.performance.getEntriesByType("resource");
        const resourcePerformanceData = JSON.stringify(resourceList);
        const resourceBlob = new Blob([resourcePerformanceData], { type: "application/json"})
        const resourceUrl = URL.createObjectURL(resourceBlob);
        const resourceLink = document.createElement("a");

        resourceLink.download = "oac-resources.log";
        resourceLink.href = resourceUrl;
        resourceLink.click();
    }

    const sendEmail = () => {
        const email = 'sdesk@adobe.com';
        const subject = 'Facing trouble with OAC';
        const body = `Hello Team,%0D%0A%0D%0AWe are facing issues with OAC, please refer the below details.%0D%0A%0D%0AProblem: %0D%0AStart time: %0D%0AImpact: %0D%0ASeverity: %0D%0ACall center: %0D%0AImpact: %0D%0AMode of work: %0D%0A%0D%0A// We request you to attach the downloaded log files to this email.`
        
        window.open(`mailto:${email}?subject=${subject}&body=${body}`)
    }
    
    const bindConsoleLog = () => {
        // @ts-ignore
        console.stdlog = console.log.bind(console);
        // @ts-ignore
        console.logs = [];
        console.log = function(){
        // @ts-ignore
        console.logs.push(Array.from(arguments));
        // @ts-ignore
        console.stdlog.apply(console, arguments);
        }
    }

    const bindErrorLog = () => {
        // @ts-ignore
        console.defaultError = console.error.bind(console);
        // @ts-ignore
        console.errors = [];
        console.error = function(){
        // @ts-ignore
        console.errors.push(Array.from(arguments));
        // @ts-ignore
        console.defaultError.apply(console, arguments);
        }
    }

    const toggleLogCaptureOn = () => {
        setToggleLogger(true);
        bindConsoleLog();
        bindErrorLog();
        setTimeout( () => {
            if(toggleLoggerRef.current) {
                alert("Auto killing the log collector")
                setToggleLogger(false);
                downloadLogFiles();
                sendEmail();
            }
        }, CAPTURE_LOG_TIMEOUT)
    }

    const toggleLogCaptureOff = () => {
        if(toggleLogger) {
            setToggleLogger(false);
            downloadLogFiles();
            sendEmail();
          }
    }

    return (
        <React.Fragment>
            {oaclogger === 'true' &&
            <div> 
                <Divider size='S' UNSAFE_className={styles['nav-divider']}/>            
                <div className={styles['nav-link-features']}>
                    <DialogTrigger >
                    <ToggleButton
                        isQuiet
                        isEmphasized
                        UNSAFE_className={styles['nav-button-no-pad']}
                        aria-label='Capture the logs' 
                        isSelected={toggleLogger}
                    >
                        <LogIcon/>
                    </ToggleButton>
                        <AlertDialog
                        variant="confirmation"
                        title="OAC Log Capture"
                        primaryActionLabel="Confirm"
                        cancelLabel="Cancel"
                        onPrimaryAction={!toggleLogger ? toggleLogCaptureOn : toggleLogCaptureOff}
                        >
                        {!toggleLogger ? 
                            <div>
                            <div>Do you wish to enable logging?</div>
                            <br/>
                            <div><b>Note:</b></div>
                            <ul>
                            <li>The agent is advised to perform the actions to reproduce the issue.</li>
                            <li>The log capture is enabled for maximum 1 minute post which it will auto-kill.</li>
                            <li>The Agent can also disable the log capture by clicking on it to toggle off.</li>
                            </ul>
                            </div>
                            : 'Stop the logging?'}
                        </AlertDialog>
                    </DialogTrigger>
                </div></div> }
        </React.Fragment>
    )
}

export default BrowserLogCapture;