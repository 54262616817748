export const agentStatusLight: any = {
  Available: 'positive',
  Away: 'notice',
  'Start of Day': 'neutral',
  'Personal MOM': 'notice',
  'PC Recovery': 'notice',
  'ACW Manual': 'notice',
  Lunch: 'notice',
  Case: 'notice',
  Email: 'notice',
  'Bomgar / Tech Support': 'notice',
  Break: 'notice',
  Coaching: 'notice',
  TRNL: 'notice',
  EOS: 'notice',
  'MEET / Non-Bill': 'notice',
  'Mentor / One Up': 'notice',
  'Outbound' : 'notice',
  'PRSNL': 'notice',
  'Sales Wrap': 'notice',
  'Nesting': 'notice',
  'Billable Training': 'notice'
};

export const agentStatusCategory: any = {
  Available: 'available',
  Away: 'away',
  'Start of Day': 'startOfDay',
  'Personal MOM': 'away',
  'PC Recovery': 'away',
  'ACW Manual': 'away',
  Lunch: 'away',
  Case: 'away',
  Email: 'away',
  'Bomgar / Tech Support': 'away',
  Break: 'away',
  Coaching: 'away',
  TRNL: 'away',
  EOS: 'away',
  'MEET / Non-Bill': 'away',
  'Mentor / One Up': 'away',
  'Outbound' : 'away',
  'PRSNL': 'away',
  'Sales Wrap': 'away',
  'Nesting': 'away',
  'Billable Training': 'away'
}