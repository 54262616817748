import React from "react";


export const TransferIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="23.686" height="11.99" viewBox="0 0 23.686 11.99">
        <g id="Group_162167" data-name="Group 162167" transform="translate(-3.676 -10)">
            <path id="Path_84275" data-name="Path 84275" d="M6.747,9.836a.324.324,0,0,0-.566.215v2.62H1.648A.648.648,0,0,0,1,13.319v2.59a.648.648,0,0,0,.648.648H6.181v2.472a.324.324,0,0,0,.566.215l4.615-4.63Z" transform="translate(16 1.096)" fill="#b3b3b3" />
            <path id="Path_84322" data-name="Path 84322" d="M1.544,6A1.544,1.544,0,0,0,0,7.544v6.177a1.544,1.544,0,0,0,1.544,1.544h.515v2.467a.257.257,0,0,0,.44.182l2.649-2.649H9.78a1.544,1.544,0,0,0,1.544-1.544V7.544A1.544,1.544,0,0,0,9.78,6Z" transform="translate(3.676 4)" fill="#b3b3b3" />
        </g>
    </svg>

)

export const ConversationAssigned = () => (
    <svg id="icon_-_conversation_assigned" data-name="icon - conversation assigned" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
        <rect id="Rectangle_150861" data-name="Rectangle 150861" width="18" height="18" fill="#fff" opacity="0" />
        <g id="Group_161571" data-name="Group 161571" transform="translate(116.482 1848)">
            <path id="Path_42" data-name="Path 42" d="M17.791,10.286H7.283L9.866,7.7l.494-.494L9.143,6,4,11.143l5.143,5.143,1.209-1.209L7.283,12H17.791Z" transform="translate(-119 -1854)" fill="#fff" />
            <path id="Path_43" data-name="Path 43" d="M13.791,4.286H3.283L5.866,1.7l.494-.494L5.143,0,0,5.143l5.143,5.143L6.351,9.077,3.283,6H13.791Z" transform="translate(-99.209 -1830) rotate(180)" fill="#fff" />
        </g>
    </svg>
)

export const Dialpad = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <g id="Group_161314" data-name="Group 161314" transform="translate(-281 -860)">
            <circle id="Ellipse_9633" data-name="Ellipse 9633" cx="2" cy="2" r="2" transform="translate(281 860)" fill="currentColor" />
            <circle id="Ellipse_9633-2" data-name="Ellipse 9633" cx="2" cy="2" r="2" transform="translate(287 860)" fill="currentColor" />
            <circle id="Ellipse_9633-3" data-name="Ellipse 9633" cx="2" cy="2" r="2" transform="translate(293 860)" fill="currentColor" />
            <circle id="Ellipse_9633-4" data-name="Ellipse 9633" cx="2" cy="2" r="2" transform="translate(281 866)" fill="currentColor" />
            <circle id="Ellipse_9633-5" data-name="Ellipse 9633" cx="2" cy="2" r="2" transform="translate(287 866)" fill="currentColor" />
            <circle id="Ellipse_9633-6" data-name="Ellipse 9633" cx="2" cy="2" r="2" transform="translate(293 866)" fill="currentColor" />
            <circle id="Ellipse_9633-7" data-name="Ellipse 9633" cx="2" cy="2" r="2" transform="translate(281 872)" fill="currentColor" />
            <circle id="Ellipse_9633-8" data-name="Ellipse 9633" cx="2" cy="2" r="2" transform="translate(287 872)" fill="currentColor" />
            <circle id="Ellipse_9633-9" data-name="Ellipse 9633" cx="2" cy="2" r="2" transform="translate(293 872)" fill="currentColor" />
        </g>
    </svg>

)

export const EndCall = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16.58" height="14.974" viewBox="0 0 16.58 14.974">
        <g id="Group_161327" data-name="Group 161327" transform="translate(-434 -861.026)">
            <path id="Icon_map-volume-control-telephone" data-name="Icon map-volume-control-telephone" d="M4.248,4.685H5.388c.342,0,.56-.5.56-.841V.257c0-.339-.218-.245-.56-.245H4.248Zm0,6.8H5.388c.342,0,.56.394.56.734V15.8c0,.337-.218.777-.56.777H4.248Zm-1.5-3.4c-.01-3.278.607-3.4.958-3.4h.121V.012H3.678C3.052.012,0,1.041,0,8.3S3.052,16.58,3.678,16.58h.145v-5.1H3.7C3.351,11.482,2.754,11.361,2.744,8.084Z" transform="translate(450.58 861.026) rotate(90)" fill="currentColor" />
            <path id="Polygon_3" data-name="Polygon 3" d="M4.5,0,9,5H0Z" transform="translate(447 876) rotate(180)" fill="currentColor" />
        </g>
    </svg>

)

export const Close = () => (
    <svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 18 18" width="18">
        <rect id="Canvas" fill="#ff13dc" opacity="0" width="18" height="18" /><path fill="#464646" d="M13.2425,3.343,9,7.586,4.7575,3.343a.5.5,0,0,0-.707,0L3.343,4.05a.5.5,0,0,0,0,.707L7.586,9,3.343,13.2425a.5.5,0,0,0,0,.707l.707.7075a.5.5,0,0,0,.707,0L9,10.414l4.2425,4.243a.5.5,0,0,0,.707,0l.7075-.707a.5.5,0,0,0,0-.707L10.414,9l4.243-4.2425a.5.5,0,0,0,0-.707L13.95,3.343a.5.5,0,0,0-.70711-.00039Z" />
    </svg>
)

export const ChevronLeft = () => (
    <svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 18 18" width="18">
        <rect id="Canvas" fill="#ff13dc" opacity="0" width="18" height="18" /><path fill="#0d66d0" d="M6,9a.994.994,0,0,0,.2925.7045l3.9915,3.99a1,1,0,1,0,1.4355-1.386l-.0245-.0245L8.4095,9l3.286-3.285A1,1,0,0,0,10.309,4.28l-.0245.0245L6.293,8.2945A.994.994,0,0,0,6,9Z" />
    </svg>
)

export const Clock = () => (
    <svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 18 18" width="18">
        <rect id="Canvas" fill="#ff13dc" opacity="0" width="18" height="18" /><path fill="#fff" d="M9,1a8,8,0,1,0,8,8A8,8,0,0,0,9,1ZM9,16a7,7,0,1,1,7-7A7,7,0,0,1,9,16Z" />
        <path fill="#fff" d="M10,8.043V3.5A.5.5,0,0,0,9.5,3h-1a.5.5,0,0,0-.5.5V8.793a.5.5,0,0,0,.1465.3535L10.95,11.95a.5.5,0,0,0,.70711.00039L11.6575,11.95l.6675-.668a.5.5,0,0,0,0-.707L10.1465,8.396A.5.5,0,0,1,10,8.043Z" />
    </svg >
)