interface ActionObject {
  [key: string]: string
}

const actionTypes: ActionObject = {
  GET_CASES_API: 'GET_CASES_API',
  LOAD_CUSTOMER_RESP: 'LOAD_CUSTOMER_RESP',
  CASE_CREATED: 'CASE_CREATED',
  READ_ONLY_MODE: 'READ_ONLY_MODE',
  CASE_LIST_PAGE_FLAG: 'CASE_LIST_PAGE_FLAG',
  TOGGLE_NOTIFICATION: 'TOGGLE_NOTIFICATION',
  SET_LANGUAGE: 'SET_LANGUAGE',
  SET_CASE_LIST: 'SET_CASE_LIST',
  TOGGLE_DARK_MODE: 'TOGGLE_DARK_MODE',
  SET_CONTEXT_DATA: 'SET_CONTEXT_DATA',
  SET_LOAD_CUSTOMER_DATA: 'SET_LOAD_CUSTOMER_DATA',
  SET_SERVICE_BASE_URL: 'SET_SERVICE_BASE_URL',
  SET_AGENT_CONSOLE_SERVICE_BASE_URL: 'SET_AGENT_CONSOLE_SERVICE_BASE_URL',
  SET_CASE_DEEPLINK_URL: 'SET_CASE_DEEPLINK_URL',
  SET_CUSTOMER_DEEPLINK_URL: 'SET_CUSTOMER_DEEPLINK_URL',
  SET_ACTIVE_CASE: 'SET_ACTIVE_CASE',
  SET_CUSTOMER_GUID: 'SET_CUSTOMER_GUID',
  SET_ACTIVE_CONVERSATION_DATA: 'SET_ACTIVE_CONVERSATION_DATA',
  SET_CREATE_CASE_PARAMS: 'SET_CREATE_CASE_PARAMS',
  CLEAR_UI_STATE: 'CLEAR_UI_STATE',
  CLEAR_CUSTOMER_STATE: 'CLEAR_CUSTOMER_STATE',
  CLEAR_CASE_STATE: 'CLEAR_CASE_STATE',
  CLEAR_OC_STATE: 'CLEAR_OC_STATE',
  SET_CHANGE_CUSTOMER: 'SET_CHANGE_CUSTOMER',
  SET_SEARCH_CUSTOMER: 'SET_SEARCH_CUSTOMER',
  SET_CONVERSATION_ARRAY : 'SET_CONVERSATION_ARRAY',
  ALLOW_TO_LINK_CASE_FOR_RETURN_CUSTOMER: 'ALLOW_TO_LINK_CASE_FOR_RETURN_CUSTOMER',
}

export default actionTypes
