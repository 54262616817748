import actionTypes from '../actionTypes'

const {
  SET_ACTIVE_CONVERSATION_DATA,
  SET_CONTEXT_DATA,
  CLEAR_OC_STATE,
  SET_CONVERSATION_ARRAY,
  ALLOW_TO_LINK_CASE_FOR_RETURN_CUSTOMER
} = actionTypes

const initialState = {
  activeConversationData: {},
  contextData: {},
  conversationIdArray : [],
  allowToLinkCaseForReturnCustomer: true
}

interface Action {
  type: string
  value: any
}

const ocReducer = (
  state: object = initialState,
  action: Action = { type: '', value: {} }
) => {
  switch (action.type) {
    case SET_ACTIVE_CONVERSATION_DATA: {
      return {
        ...state,
        activeConversationData: action.value
      }
    }
    case SET_CONTEXT_DATA: {
      return {
        ...state,
        contextData: action.value
      }
    }
    case SET_CONVERSATION_ARRAY: {
      return {
        ...state,
        conversationIdArray: action.value
      }
    }
    case CLEAR_OC_STATE: {
      return initialState
    }
    case ALLOW_TO_LINK_CASE_FOR_RETURN_CUSTOMER:{
      return {
        ...state,
        allowToLinkCaseForReturnCustomer: action.value
      }
    }
    default:
      return state
  }
}

export default ocReducer
