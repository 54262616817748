/**
 * Parses a duration string and converts it to total seconds.
 * 
 * @param {string} duration - The duration string (e.g., "2h 30m 15s").
 * @returns {number} - The total duration in seconds.
 */
export const parseDuration = (duration: string) => {
    const regex = /(\d+)([hms])/g;
    let totalSeconds = 0;

    let match;
    while ((match = regex.exec(duration))) {
        const value = parseInt(match[1], 10);
        const unit = match[2];
        if (unit === 'h') totalSeconds += value * 3600;
        else if (unit === 'm') totalSeconds += value * 60;
        else if (unit === 's') totalSeconds += value;
    }

    return totalSeconds;
};

/**
 * Formats a duration given in total seconds into a human-readable string.
 * 
 * The function calculates the number of hours, minutes, and seconds from 
 * the total seconds provided, and constructs a formatted string that 
 * represents the duration.
 * 
 * @param {number} totalSeconds - The total duration in seconds to be formatted.
 * @returns {string} - A string representing the duration in hours, minutes, and seconds. (Eg: 6h 10m 5s)
 */
export const formatDuration = (totalSeconds: any) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    let formatted = '';
    if (hours > 0) formatted += `${hours}h `;
    if (minutes > 0 || hours > 0) formatted += `${minutes}m `;
    formatted += `${seconds}s`;

    return formatted.trim();
};

/**
 * Adds two time durations
 * 
 * The function calculates the sum of two durations in the format Xh Xm Ys 
 * and returns back the sum in the same format
 * 
 * @param {string} existingDuration - The current/existing duration for the given AUX category.
 * @param {number} totalSeconds - The addtional duration for the AUX category.
 * @returns {string} - A string representing the duration in hours, minutes, and seconds. (Eg: 6h 10m 5s)
 */
export const addDuration = (existingDuration: string, additionalDuration: string) => {
    const existingSeconds = parseDuration(existingDuration);
    const additionalSeconds = parseDuration(additionalDuration);
    const totalSeconds = existingSeconds + additionalSeconds;
    return formatDuration(totalSeconds);
};