import { useState } from 'react'
import { fetchClosedContactLegSentiments } from '../services/api'

/**
 * Hook for fetching the sentiments of each contact leg of a closed contact
 */
const useGetClosedContactPathSentimentHook = (url: string) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>();

  const getSentiments = async (initialContactId: string) => {
    // fetch the sentiments from the backend
    setLoading(true)
    setError(null)
    return fetchClosedContactLegSentiments(url, initialContactId)
      .catch((err) => {
        console.log('Error fetching the contact leg sentiments for a closed contact: ', err?.message ?? 'Internal Error.')
        setError(err.message ?? 'Error fetching sentiments')
        throw err
      })
      .finally(() => setLoading(false))
  }

  return {
    error,
    loading,
    getSentiments
  }
}

export default useGetClosedContactPathSentimentHook
