import React from 'react'
import { ActionButton, Divider, Tooltip, TooltipTrigger } from '@adobe/react-spectrum'

import styles from '../../Theme/Styles/styles.css'
import { CCPLogIcon } from '../../Theme/Icons/navbar-icons'

const BrowserLogCapture = (props: any) => {
    return (
        <React.Fragment>
            <div> 
                <Divider size='S' UNSAFE_className={styles['nav-divider']}/>            
                <div className={styles['nav-link-features']}>
                <TooltipTrigger>
                    <ActionButton UNSAFE_className={styles['ccp-download-action-button']} onPress={() => props.callBackEvent('download-ccp-logs')} isQuiet staticColor='white'>
                        <CCPLogIcon/>
                    </ActionButton>
                    <Tooltip UNSAFE_className={styles.tooltip} width={80}>Download CCP Logs</Tooltip>
                </TooltipTrigger>
                </div>
            </div>
        </React.Fragment>
    )
}

export default BrowserLogCapture;