import axios from 'axios';
import find from 'lodash/find';

async function customerIdValidationForCIS(props: any, urls: any, jcAuthData: any) {
  const { CIP_DOMAIN } = urls;
  const KEY_FOR_T2E = 'type2e';
  const KEY_FOR_ADOBEID = 'type1';
  let isPrechatFlow = false;
  const originalRengaId = props.SCIPData[0]['Renga ID'][0] || '';
  const lowerCaseRengaId = originalRengaId.toLowerCase();
  const finalRes: any = {
    rengaId: originalRengaId,
    success: true,
    message: '',
  };
  if (lowerCaseRengaId.includes('@adobeid')) {
    return finalRes;
  }
  let searchResult = props.searchCustomerResponse;
  if (!(searchResult && searchResult.length > 1)) {
    const conversationId = props.SCIPData[3]['Phone ID'][0] ? props.SCIPData[3]['Phone ID'][0] : props.SCIPData[3]['Chat ID'][0] || '';
    const email = props.SCIPData[0]['Email Address'][0] || '';
    const phone = props.SCIPData[0]?.['Phone Number']?.[0] || '';
    if (email && phone) {
      const params = {
        conversationId,
        email,
        phone,
      };
      try {
        const searchResponse: any = await axios.post(`${CIP_DOMAIN}/api/v1/customer/search`, params, {
          headers: { 'x-api-key': 'oac-custom-panel', Authorization: localStorage.getItem('customPanelAccessToken').replaceAll('"', '') ?? '' },
        });
        console.log('SCIP CIS searchResponse called', params);
        searchResult = searchResponse && searchResponse.data && searchResponse.data.json ? searchResponse.data.json : [];
      } catch (err) {
        console.error('Search customer API inside CIS failed.');
      }
    }
  }
  const filteredRengaID: any = find(searchResult, (cust: any) => cust.rengaId === originalRengaId);

  const newRengaID_Type1: any = find(searchResult, (cust: any) => cust.type === KEY_FOR_ADOBEID);

  const newRengaID_NON_T2E: any = find(searchResult, (cust: any) => cust.type !== KEY_FOR_T2E);
  const newRengaId_Priority2 = newRengaID_NON_T2E && newRengaID_NON_T2E.rengaId ? newRengaID_NON_T2E.rengaId : '';
  const newRengaId_Priority1 = newRengaID_Type1 && newRengaID_Type1.rengaId ? newRengaID_Type1.rengaId : '';
  const newRengaId = newRengaId_Priority1 || newRengaId_Priority2;
  const rengaIdType = filteredRengaID && filteredRengaID.type ? filteredRengaID.type : '';

  // Check if its a Prechat flow
  isPrechatFlow = jcAuthData?.customerType && jcAuthData.email && jcAuthData.customerType.toLowerCase() === 'unauthenticated';
  // RengaId type not found and Original Renga Id is Integer then it is Zendesk Flow
  if (!rengaIdType) {
    if (isInteger(originalRengaId)) {
      finalRes.success = !!newRengaId;
      finalRes.message = newRengaId ? 'Zendesk Flow : Choosing Type1 RengaId' : 'Zendesk Flow ';
      finalRes.rengaId = newRengaId;
      return finalRes;
    }
    // UnAuth Flow
    if (isPrechatFlow) {
      let name = '';
      if (searchResult.length > 0) {
        const payload: any = searchResult[0];
        name = payload.firstName + (payload.lastName ? ` ${payload.lastName}` : '');
      }
      finalRes.success = !!newRengaId;
      finalRes.rengaId = newRengaId;
      finalRes.message = 'PreChat Flow';
      finalRes.name = name;
      return finalRes;
    }
    finalRes.success = false;
    finalRes.message = 'Customer Type is Invalid';
    return finalRes;
  }
  // RengaId passed is T2E Account ( Ent to Sales Transfer and No change Customer By Agent)
  if (rengaIdType === KEY_FOR_T2E) {
    finalRes.success = !!newRengaId;
    finalRes.message = newRengaId ? 'T2E Flow : Choosing Type1 RengaId' : 'T2E Flow ';
    finalRes.rengaId = newRengaId;
    finalRes.linkedAccount = originalRengaId;
    return finalRes;
  }
  return finalRes;
}

function isInteger(value: any) {
  return /^\d+$/.test(value);
}

/**
 * Util function that implements exponential backoff retry logic
 * This function accepts a retryConditional that receives the return value of the function call and determines if it should be retried (for successful invocations but that return bad or empty data)
 * This function will also trigger the retry logic if the function throws an error without checking the retryConditional
 * @param fn the function to call
 * @param retryConditional a conditional that receives the result of the function call and determines if it should be retried
 * @param maxRetries max number of times to retry (default = 3)
 * @param baseDelay the base delay for the first time (default = 100 ms)
 * @param maxDelay the max delay in ms
 * @param retryCount the number of times the function has retried
 * @returns a promise that will fulfill eventually or throw
 */
export const retryFunction = async<T>(
  fn: (...args: any) => Promise<T>,
  retryConditional: (args: T) => boolean,
  maxRetries: number = 3,
  baseDelay: number = 100,
  maxDelay: number = 10000,
  retryCount: number = 0
): Promise<T> => {
  try {
    const res = await fn()
    // trigger retry if retryCondition is satisfied
    if (retryConditional(res) && retryCount < maxRetries) {
      retryCount++;
      const delay = Math.min(baseDelay * Math.pow(2, retryCount - 1), maxDelay)
      await new Promise((resolve) => setTimeout(resolve, delay))
      return retryFunction(fn, retryConditional, maxRetries, baseDelay, maxDelay, retryCount)
    }

    return res
  } catch (err) {
    // also trigger retries on error
    if (retryCount < maxRetries) {
      retryCount++;
      const delay = Math.min(baseDelay * Math.pow(2, retryCount - 1), maxDelay)
      await new Promise((resolve) => setTimeout(resolve, delay))
      return retryFunction(fn, retryConditional, maxRetries, baseDelay, maxDelay, retryCount)
    } else {
      throw err
    }
  }
}

/**
 * 
 * @param closedPhoneCall 
 * @param closedConversation 
 * @param multipleAgentsConnected
 * @param assignedAgent 
 * @returns widget mode
 * Widget should be set to read only mode only if both chat and phone on the conversation are closed
 * Incase, of Multiparty we should additionaly check for assigned Agent property to determine if widget should be in editable mode
 */
export const getConversationStatus = (closedPhoneCall: boolean, closedConversation: boolean, multipleAgentsConnected: any, assignedAgent: any) => {
  if (closedPhoneCall && closedConversation) {
    return true;
  }
  if (multipleAgentsConnected && typeof multipleAgentsConnected == "boolean") {
    return !assignedAgent;
  }
  return closedPhoneCall && closedConversation;
}


export function getRouteToMarketValue(value: string) {
  const routeToMarket = {
    'inbound-chat': 'inboundChat',
    'chat-to-phone': 'chatToPhone',
    'outbound-phone': 'outboundPhone',
    'inbound-phone': 'inboundPhone',
    'bot-to-phone': 'botToPhone',
  };
  return routeToMarket[value];
}

export default {
  customerIdValidationForCIS,
};
