import React from "react";

export const CallIcon = () => (
    <svg className="call-Icon" id="Group_162090" data-name="Group 162090" xmlns="http://www.w3.org/2000/svg" width="17.667" height="17.848" viewBox="0 0 17.667 17.848">
        <path id="Union_66" data-name="Union 66" d="M1.618,6.2V3.42H0L3.162,0,6.324,3.42H4.854V6.2Z" transform="translate(13.195 0) rotate(45)" fill="#fff" />
        <path id="Icon_map-volume-control-telephone" data-name="Icon map-volume-control-telephone" d="M4.08,4.5H5.174c.328,0,.538-.482.538-.808V.247c0-.326-.21-.235-.538-.235H4.08Zm0,6.528H5.174c.328,0,.538.379.538.705v3.444c0,.324-.21.747-.538.747H4.08ZM2.636,7.764c-.009-3.149.583-3.264.92-3.264h.116V.012h-.14C2.932.012,0,1,0,7.968s2.932,7.957,3.533,7.957h.14v-4.9H3.556C3.218,11.028,2.645,10.911,2.636,7.764Z" transform="translate(0 6.588) rotate(-45)" fill="#fff" />
    </svg>
)

export const End = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18.062" viewBox="0 0 20 18.062">
        <g id="Group_161327" data-name="Group 161327" transform="translate(0)">
            <path id="Icon_map-volume-control-telephone" data-name="Icon map-volume-control-telephone" d="M5.124,5.652H6.5c.412,0,.675-.606.675-1.015V.31c0-.409-.263-.3-.675-.3H5.124Zm0,8.2H6.5c.412,0,.675.476.675.886v4.326c0,.407-.263.938-.675.938H5.124ZM3.31,9.751c-.012-3.955.732-4.1,1.156-4.1h.146V.015H4.437C3.682.015,0,1.255,0,10.007S3.682,20,4.437,20h.175V13.851H4.466C4.042,13.851,3.322,13.7,3.31,9.751Z" transform="translate(20) rotate(90)" fill="#fff" />
            <path id="Polygon_3" data-name="Polygon 3" d="M5.428,0l5.428,6.031H0Z" transform="translate(15.682 18.062) rotate(180)" fill="#fff" />
        </g>
    </svg>
)

export const Hold = () => (
    <svg id="Decorative_icon" data-name="Decorative icon" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <rect id="Placement_Area" data-name="Placement Area" width="20" height="20" fill="currentColor" opacity="0" />
        <g id="Icon">
            <rect id="Canvas" width="20" height="20" fill="currentColor" opacity="0" />
            <rect id="Rectangle_150673" data-name="Rectangle 150673" width="4.444" height="15.556" rx="0.5" transform="translate(3.333 2.222)" fill="currentColor" />
            <rect id="Rectangle_150674" data-name="Rectangle 150674" width="4.444" height="15.556" rx="0.5" transform="translate(11.111 2.222)" fill="currentColor" />
        </g>
    </svg>

)

export const Mute = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="17.501" height="25.456" viewBox="0 0 17.501 25.456">
        <path id="Union_78" data-name="Union 78" d="M10633.125-3538.543a.749.749,0,0,1-.75-.748.749.749,0,0,1,.75-.751h8.251a.749.749,0,0,1,.75.751.749.749,0,0,1-.75.748Zm3.375-2.992v-2.295a8.261,8.261,0,0,1-7.5-8.213.749.749,0,0,1,.751-.748.747.747,0,0,1,.748.748,6.759,6.759,0,0,0,6.751,6.751,6.758,6.758,0,0,0,6.749-6.751.749.749,0,0,1,.751-.748.749.749,0,0,1,.751.748,8.261,8.261,0,0,1-7.5,8.213v2.295a.751.751,0,0,1-.751.751A.751.751,0,0,1,10636.5-3541.535Zm-2.407-6.61a5.141,5.141,0,0,1-1.729-2.856h2.383a.5.5,0,0,0,.5-.5.5.5,0,0,0-.5-.5h-2.5v-2h2.5a.5.5,0,0,0,.5-.5.5.5,0,0,0-.5-.5h-2.5v-2h2.5a.5.5,0,0,0,.5-.5.5.5,0,0,0-.5-.5h-2.5a5.127,5.127,0,0,1,1.493-3.54,4.931,4.931,0,0,1,3.506-1.461,4.932,4.932,0,0,1,3.508,1.461,5.122,5.122,0,0,1,1.493,3.54h-2.5a.5.5,0,0,0-.5.5.5.5,0,0,0,.5.5h2.5v2h-2.5a.5.5,0,0,0-.5.5.5.5,0,0,0,.5.5h2.5v2h-2.5a.5.5,0,0,0-.5.5.5.5,0,0,0,.5.5h2.384a5.139,5.139,0,0,1-1.729,2.856,4.957,4.957,0,0,1-3.157,1.143A4.961,4.961,0,0,1,10634.093-3548.145Z" transform="translate(-10628.5 3563.499)" fill="currentColor" stroke="rgba(0,0,0,0)" strokeMiterlimit="10" strokeWidth="1" />
    </svg>

)

export const Join = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="14.823" height="18.038" viewBox="0 0 14.823 18.038">
        <path id="Union_99" data-name="Union 99" d="M-5198.588-7018.044l-4.278,4.081-3.134-2.99,5.3-5.054v-4.525h-3.215l5.33-5.469,5.333,5.469h-3.016v4.719l5.1,4.86-3.134,2.99Z" transform="translate(5206 7032.001)" fill="#b3b3b3" />
    </svg>

)

export const Swap = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="28.606" height="17.606" viewBox="0 0 28.606 17.606">
        <g id="Group_162233" data-name="Group 162233" transform="translate(-461.696 -346.197)">
            <path id="Union_102" data-name="Union 102" d="M2.216,13.235V4.469H6.647v8.767ZM-1,4.469,4.331-1,9.662,4.469Z" transform="translate(469.236 362.388) rotate(-135)" fill="#b3b3b3" />
            <path id="Union_103" data-name="Union 103" d="M2.216,13.235V4.469H6.647v8.767ZM-1,4.469,4.331-1,9.662,4.469Z" transform="translate(482.763 347.611) rotate(45)" fill="#b3b3b3" />
        </g>
    </svg>

)

export const Transfer = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="27.055" height="12.099" viewBox="0 0 27.055 12.099">
        <g id="Group_162234" data-name="Group 162234" transform="translate(-6 -13.951)">
            <path id="Path_84315" data-name="Path 84315" d="M8.223,9.864a.407.407,0,0,0-.711.27v3.293h-5.7A.814.814,0,0,0,1,14.241V17.5a.814.814,0,0,0,.814.814h5.7v3.108a.407.407,0,0,0,.711.27l5.8-5.82Z" transform="translate(19.031 4.224)" fill="#b3b3b3" />
            <path id="Path_84323" data-name="Path 84323" d="M3.631,72.053l.692-.692c.207-.207.035-.645-.171-.851L1.974,68.332c-.206-.206-.281-.015-.488.192l-.692.692Zm4.128,4.128.692-.692a.586.586,0,0,1,.785.105l2.181,2.181c.2.2.339.6.132.812l-.692.692ZM4.786,75.026c-2-1.984-1.7-2.432-1.482-2.646l.074-.074L.54,69.469l-.091.091c-.383.383-1.607,2.865,2.795,7.267s6.884,3.178,7.265,2.8l.091-.091-3.1-3.1-.076.076c-.214.214-.65.5-2.647-1.483Z" transform="translate(6 -54.021)" fill="#b3b3b3" />
        </g>
    </svg>

)

export const DialogIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <g id="Group_161867" data-name="Group 161867" transform="translate(-281 -860)">
            <circle id="Ellipse_9633" data-name="Ellipse 9633" cx="2" cy="2" r="2" transform="translate(281 860)" fill="currentColor" />
            <circle id="Ellipse_9633-2" data-name="Ellipse 9633" cx="2" cy="2" r="2" transform="translate(287 860)" fill="currentColor" />
            <circle id="Ellipse_9633-3" data-name="Ellipse 9633" cx="2" cy="2" r="2" transform="translate(293 860)" fill="currentColor" />
            <circle id="Ellipse_9633-4" data-name="Ellipse 9633" cx="2" cy="2" r="2" transform="translate(281 866)" fill="currentColor" />
            <circle id="Ellipse_9633-5" data-name="Ellipse 9633" cx="2" cy="2" r="2" transform="translate(287 866)" fill="currentColor" />
            <circle id="Ellipse_9633-6" data-name="Ellipse 9633" cx="2" cy="2" r="2" transform="translate(293 866)" fill="currentColor" />
            <circle id="Ellipse_9633-7" data-name="Ellipse 9633" cx="2" cy="2" r="2" transform="translate(281 872)" fill="currentColor" />
            <circle id="Ellipse_9633-8" data-name="Ellipse 9633" cx="2" cy="2" r="2" transform="translate(287 872)" fill="currentColor" />
            <circle id="Ellipse_9633-9" data-name="Ellipse 9633" cx="2" cy="2" r="2" transform="translate(293 872)" fill="currentColor" />
        </g>
    </svg>
)

export const Alert = () => (
    <svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 18 18" width="18">
        <rect id="Canvas" fill="#ff13dc" opacity="0" width="18" height="18" /><path fill="#D7373F" d="M8.5635,1.2895.2,16.256A.5.5,0,0,0,.636,17H17.364a.5.5,0,0,0,.436-.744L9.4365,1.2895a.5.5,0,0,0-.873,0ZM10,14.75a.25.25,0,0,1-.25.25H8.25A.25.25,0,0,1,8,14.75v-1.5A.25.25,0,0,1,8.25,13h1.5a.25.25,0,0,1,.25.25Zm0-3a.25.25,0,0,1-.25.25H8.25A.25.25,0,0,1,8,11.75v-6a.25.25,0,0,1,.25-.25h1.5a.25.25,0,0,1,.25.25Z" />
    </svg>
)

export const ArrowDown = () => (
    <svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 18 18" width="18">
        <rect id="Canvas" fill="#ff13dc" opacity="0" width="18" height="18" /><path d="M4,7.01a1,1,0,0,1,1.7055-.7055l3.289,3.286,3.289-3.286a1,1,0,0,1,1.437,1.3865l-.0245.0245L9.7,11.7075a1,1,0,0,1-1.4125,0L4.293,7.716A.9945.9945,0,0,1,4,7.01Z" />
    </svg>
)