import React from 'react'

type Props = {
  fill?: string,
  style?: React.CSSProperties
}

const SentimentNeutral = ({ fill = '#464646', style }: Props) => (
  <svg aria-labelledby='neutral sentiment' style={style} xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 18 18" width="18">
    <rect id="Canvas" fill="#ff13dc" opacity="0" width="18" height="18" /><path style={{ fill }} d="M9,1a8,8,0,1,0,8,8A8,8,0,0,0,9,1ZM6.1825,5.267a1.71956,1.71956,0,0,1,1.5,1.875,1.71956,1.71956,0,0,1-1.5,1.875,1.71956,1.71956,0,0,1-1.5-1.875A1.71921,1.71921,0,0,1,6.1825,5.267ZM11.6,13H6.4a.4.4,0,0,1-.4-.4v-.2a.4.4,0,0,1,.4-.4h5.2a.4.4,0,0,1,.4.4v.2A.4.4,0,0,1,11.6,13Zm.1365-4.034a1.71921,1.71921,0,0,1-1.5-1.875,1.71921,1.71921,0,0,1,1.5-1.875,1.71921,1.71921,0,0,1,1.5,1.875A1.71921,1.71921,0,0,1,11.7365,8.966Z" />
  </svg>
)

export default SentimentNeutral
